<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          v-if="canEdit"
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
        >
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Tipo de régimen
        </span>
        <br />
        <span class="text-data-details">
          {{ clienteInfo.regimen ? clienteInfo.regimen : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          RFC
        </span>
        <br />
        <span class="text-data-details">
          {{ clienteInfo.rfc ? clienteInfo.rfc : "Sin llenar" }}
        </span>
      </v-col>

      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Razón Social
        </span>
        <br />
        <span class="text-data-details">
          {{ clienteInfo.razonSocial ? clienteInfo.razonSocial : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Fecha de Constitucion
        </span>
        <br />
        <span class="text-data-details">
          {{ clienteInfo.fechaConstitucion ? formatDate(clienteInfo.fechaConstitucion) : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
    };
  },
  props: {
    clienteInfo: Object,
    operadores: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getAgentName() {
      return "Nombre";
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>
