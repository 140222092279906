<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos fiscales
      </template>
      <v-expansion-panel-content>
        <ViewFiscalData
          @changeComponent="switch_data = 2"
          :clienteInfo="clienteInfo"
          :canEdit="canEdit"
          v-if="switch_data == 1"
        >
        </ViewFiscalData>
        <EditFiscalData
          @changeTab="switch_data = 1"
          @getData="$emit('getData'), switch_data = 1"
          :clienteInfo="clienteInfo"
          v-else
        >
        </EditFiscalData>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import EditFiscalData from "./EditFiscalData.vue";
import ViewFiscalData from "./ViewFiscalData.vue";

export default {
  components: {
    CommonExpansionPanel,
    EditFiscalData,
    ViewFiscalData,
  },
  props: {
    clienteInfo: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      switch_data: 1,
    };
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
  },
};
</script>
