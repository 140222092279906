<template>
  <div>
    <commond-form
      :request="formData"
      :route="'/v1/cliente/update/' + $route.params.uuid"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeTab', 1)"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-select
              label="Tipo de régimen"
              v-model="formData.regimen"
              :items="regimenList"
              outlined
              color="#00a7e4"
            ></v-select>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              label="RFC"
              v-model="formData.rfc"
              outlined
              :rules="rfcRules"
              color="#00a7e4"
              @input="onInputToUpper"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4" v-if="formData.regimen == 'Moral'">
            <v-text-field
              label="Razón Social"
              v-model="formData.razonSocial"
              outlined
              color="#00a7e4"
              :items="regimenList"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4" v-if="formData.regimen == 'Moral'">
            <v-text-field
              label="Fecha de Constitucion"
              type="date"
              v-model="formData.fechaConstitucion"
              outlined
              color="#00a7e4"
              :items="regimenList"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeTab')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  components: {
    CommondForm,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      formData: {
        id: null,
        status: 0,
        regimen: null,
        nombre: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        genero: null,
        fechaNacimiento: null,
        estadoCivil: null,
        razonSocial: null,
        fechaConstitucion: null,
        rfc: null,
        correo: null,
        celular: null,
        telefonoOficina: null,
        agenteId: null,
        direccionFacturacionId: null,
        direccionResidenciaId: null,
        fechaCreacion: null,
        creadoPor: false,
        modificadoPor: false,
      },
      inputsRequeridos: [(value) => !!value || "Campo Requerido"],
      regimenList: ["Física", "Moral"],
      rfcRules: [
        (v) => (v.length === 10 || v.length === 12 || v.length === 13) || "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      run:false
    };
  },
  props: {
    clienteInfo: Object,
    operadores: Object,
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      var keys = Object.keys(this.formData);
      keys.map((e) => {
        if (typeof this.clienteInfo[e] != "undefined") {
          this.formData[e] = this.clienteInfo[e];
        }
      });
      this.formData.status = this.clienteInfo.status;
    },
    onInputToUpper() {
      this.formData.rfc = this.formData.rfc.toUpperCase();
    },
  },
};
</script>
